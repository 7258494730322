export interface IOffer {
    id: string;
    /** The formatted size of the plan in GBs */
    size: string;
    /** Formatted price. Includes the currency symbol. */
    price: string;
    oldPrice: string;
    period: OfferPeriod;
    paymentLink: string;
}

type OfferPeriod = "3years" | "5years";

export const fetchOffers = async () => {
    try {
        const response = await fetch("https://api.ente.io/offers/black-friday");
        if (response.ok) {
            const jsonResponse = await response.json();
            return convert(jsonResponse);
        }
    } catch (error) {
        console.log(error);
    }
    return [];
};

interface IOffersResponse {
    offers: IOfferResponse[];
}

interface IOfferResponse {
    id: string;
    /** Plan storage, in bytes */
    storage: number;
    /**
     * Plan price.
     *
     * Formatted string. Includes the currency symbol.
     */
    price: string;
    oldPrice: string;
    paymentLink: string;
    period: "3years" | "5years" | string;
}

/** Convert the plans API response to the plans structure used by the UI code */
const convert = (response: IOffersResponse) => {
    // Ignore invalid plans
    return response.offers
        .map(({ id, storage, price, oldPrice, period, paymentLink }) => {
            return {
                id,
                price,
                oldPrice,
                period,
                paymentLink,
                size: `${convertStorage(storage)}`,
            };
        })
        .filter((p) => p) as IOffer[];
};

const convertStorage = (storage: number) => {
    return Math.ceil(storage / (1024 * 1024 * 1024));
};
