import Layout from "components/Layout";
import Article from "components/Article";

import Toggle from "components/Toggle";
import { Link } from "gatsby";
import ArrowRight from "images/homepage/ArrowRight";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import { IOffer, fetchOffers } from "utils/offer-service";
import FAQCard from "components/homepageSections/FAQCard";
import { StaticImage } from "gatsby-plugin-image";

const Container = styled.section.attrs({
    className: "container text-center",
})`
    margin-top: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
`;

const Label = styled.div<{ active: boolean }>`
    flex: 1;
    font-weight: 700;

    & > span {
        transition: opacity 0.25s ease-out;
        opacity: ${(props) => (props.active ? "1" : "0.5")};
    }
`;

const DiscountHeader = styled.div`
    background: linear-gradient(
        180deg,
        #1db954 0%,
        rgba(54, 236, 71, 0.87) 464.41%
    );
    font-family: "Gilroy";
    border-radius: 24px 24px 0px 0px;
    padding: 12px;
    height: 72px;
    font-weight: 700;
    font-size: 32px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CardWrapper = styled.div`
    padding: 2px;
    border-radius: 24px;
`;

const Card = styled.div`
    border-radius: 0px 0px 24px 24px;
    background: #fff;
    min-width: 220px;
    padding: 28px 20px 20px 20px;
    font-family: Gilroy;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        padding: 28px 20px 20px 20px;
    }
`;

const Storage = styled.div`
    color: #1d1d1f;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
`;

const OldPlanValue = styled.div`
    position: relative;
`;

const OldPlanPrice = styled.div`
    position: relative;
    color: #000000;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    margin-top: 12px;
`;

const PlanValue = styled.div`
    color: #1cb954;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 12px;
`;

const PlanValueMinor = styled.span`
    color: #1cb954;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 21px;
`;

const PlanPeriod = styled.span`
    color: #484848;
    font-family: "Nunito";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
`;

const PlanCTA = styled.button`
    font-family: "Gilroy";
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 100px;
    background: #000000;
    padding: 10px 26px;
    margin-top: 24px;
    width: 200px;
    display: inline-flex;
    justify-content: space-between;
    transition:
        width 0.1s linear,
        background-color 0.1s linear;
    text-decoration: none;

    &:focus {
        outline: 4px solid rgba(0, 0, 0, 0.2);
    }

    &:hover {
        background-color: #1db954;
        width: 180px;
    }

    @media (min-width: 768px) {
        width: 160px;
    }
`;

const PricingCarouselItem = styled(Carousel.Item)`
    transition: transform 0.2s ease;
`;

const Disclaimer = styled.div`
    color: grey;
    font-size: 12px;
    text-align: center;
`;

const Strike = styled.div`
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(7.782deg); /* Center the div and then rotate */
    width: 84px;
    height: 7px;
    background: black; /* Or any color you want for the strike */
    flex-shrink: 0;
    z-index: 2; /* Ensure the strike is above the text */
    background: rgba(28, 185, 84, 0.8);
`;

const SoldOut = styled.div`
    margin-top: 12px;
`;

const splitPrice = (price: string) => price.split(".");

const getPlanCard = (plan: IOffer, shouldShow3Years: boolean) => {
    const [major, minor] = splitPrice(plan.price);

    return (
        <CardWrapper>
            <DiscountHeader>
                {shouldShow3Years ? "33% OFF" : "50% OFF"}
            </DiscountHeader>
            <Card>
                <Storage>{plan.size}GB</Storage>
                <PlanPeriod>
                    for {shouldShow3Years ? "3 years" : "5 years"}
                </PlanPeriod>
                <OldPlanValue>
                    <OldPlanPrice>{plan.oldPrice}</OldPlanPrice>
                    <Strike />
                </OldPlanValue>
                <PlanValue>
                    {major}
                    {minor && <PlanValueMinor>.{minor}</PlanValueMinor>}
                </PlanValue>
                <SoldOut>
                    <StaticImage
                        src="../images/blackfriday/sold-out.png"
                        alt="Sold out"
                        objectFit="contain"
                        placeholder="none"
                        loading="lazy"
                    />
                </SoldOut>
            </Card>
        </CardWrapper>
    );
};

const getPlanCards = (
    filteredPlans: IOffer[],
    shouldShow3Years: boolean,
    carousel?: boolean,
) => {
    return filteredPlans.map((plan, index) => {
        return carousel ? (
            <PricingCarouselItem key={plan.id}>
                {getPlanCard(plan, shouldShow3Years)}
            </PricingCarouselItem>
        ) : (
            <div className="col-md-6 col-lg-4 mb-3 d-flex" key={plan.id}>
                {getPlanCard(plan, shouldShow3Years)}
            </div>
        );
    });
};

const Pricing: React.FC = () => {
    const [plans, setPlans] = useState([] as IOffer[]);
    const [shouldShow3Years, setShouldShow3Years] = useState(true);

    useEffect(() => {
        fetchOffers().then((offers) => {
            setPlans(offers);
        });
    }, []);

    const handleToggle = function () {
        setShouldShow3Years(!shouldShow3Years);
    };

    const filterPeriod = shouldShow3Years ? "3years" : "5years";
    const filteredPlans = plans.filter((plan) => plan.period === filterPeriod);

    return (
        <Container id="pricing">
            <div className="d-flex align-items-center">
                <Label
                    active={shouldShow3Years}
                    className="text-sm-end text-center"
                >
                    <span>3 years</span>
                </Label>
                <div>
                    <Toggle
                        isOn={!shouldShow3Years}
                        offLabel=""
                        onLabel=""
                        onToggle={handleToggle}
                    />
                </div>
                <Label
                    active={!shouldShow3Years}
                    className="text-sm-start text-center"
                >
                    <span>5 years</span>
                </Label>
            </div>
            <div className="mt-5 d-md-none">
                <Carousel
                    interval={null}
                    className="carousel-dark"
                    indicators={false}
                >
                    {getPlanCards(filteredPlans, shouldShow3Years, true)}
                </Carousel>
            </div>
            <div className="row mt-5 d-none d-md-flex">
                {getPlanCards(filteredPlans, shouldShow3Years)}
            </div>
        </Container>
    );
};

export default function BlackFriday() {
    return (
        <Layout title="Ente - Black Friday 2023">
            <Article>
                <Article.Header>
                    <div style={{ marginBottom: "-1rem" }}>
                        <Article.Heading className="text-center">
                            Black Friday Sale
                        </Article.Heading>
                        <div className="vstack text-center text-muted gap-md-1 pt-2">
                            <div>Buy Ente at the best price ever!</div>
                        </div>
                    </div>
                </Article.Header>

                <Article.BodyM>
                    <p className="text-center">
                        You can buy <b>one</b> of these addons, at massive
                        discounts, until 5th December.
                    </p>
                    <Pricing />
                    <Disclaimer>
                        The comparison here is with existing monthly plans.
                    </Disclaimer>
                    <hr />
                    <p>
                        "Black Friday" is the arbitrary time of the year where
                        companies offer discounts.
                    </p>
                    <p>
                        At Ente, we've never resonated with marketing techniques
                        that treat existing customers unfairly. Also, we don't
                        wish to offer unsustainable prices that undermine the
                        core value of the product. Longevity comes at a premium,
                        and we can't and won't ever monetize your data.
                    </p>
                    <p>
                        So this Black Friday, we've approached things
                        differently. We've hand picked long-term plans, that you
                        can purchase at a discount, and stack with your existing
                        subscription. You walk away with money saved, and we
                        receive an investment we can sustainably utilize.
                    </p>
                    <p>
                        These deals are accessible to all of our existing
                        customers, and we hope it'll also make it easy for those
                        on the fence to jump in.
                    </p>
                    <p>Happy shopping! 🍾</p>
                    <FAQs />
                </Article.BodyM>
            </Article>
        </Layout>
    );
}

const FAQsArr = [
    {
        question: <>What if I already have a paid subscription?</>,
        answer: (
            <>
                The storage you buy will be added to your existing subscription.
                For example, if you own a 100GB subscription, buying 100GB will
                make 200GB available. If you downgrade to a 10GB subscription,
                you will have 110GB available. If you pause your subscription,
                you will only have 100 GB available.
            </>
        ),
    },
    {
        question: <>Can I purchase multiple addons and stack them?</>,
        answer: (
            <>
                No, you can only buy a single addon, which will be stacked over
                your existing subscription.
            </>
        ),
    },
    {
        question: <>How many units are on sale?</>,
        answer: <>A total of 100 units are on sale.</>,
    },
    {
        question: <>Can I share storage with my family?</>,
        answer: <>Yes!</>,
    },
    {
        question: <>Can I pay using Crypto?</>,
        answer: (
            <>
                Yes! Please drop a mail to{" "}
                <a href="mailto:crypto@ente.io">crypto@ente.io</a> citing the
                plan of your choice and we'll share an invoice where you can
                complete the payment with a crypto-currency of your choice.
            </>
        ),
    },
    {
        question: <>Can I get a refund?</>,
        answer: (
            <>
                You can request for a refund within 14 days of making a purchase
                by writing to{" "}
                <a href="mailto:support@ente.io">support@ente.io</a>.
            </>
        ),
    },
    {
        question: <>For how long is the Black Friday deal live?</>,
        answer: <>The deal is live until 5th of December, 2023.</>,
    },
    {
        question: <>I've more questions, where can I talk to you?</>,
        answer: (
            <>
                We're available on{" "}
                <a href="https://ente.io/discord" target="_blank">
                    Discord
                </a>{" "}
                &{" "}
                <a href="https://ente.io/matrix" target="_blank">
                    Matrix
                </a>
                , come over!
            </>
        ),
    },
];

export function FAQs() {
    const [active, setActive] = useState(-1);

    return (
        <Container>
            <h2>FAQ</h2>
            {FAQsArr.map((faq, index) => (
                <FAQCard
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    onClick={() => setActive(active === index ? -1 : index)}
                    expanded={active === index}
                />
            ))}
        </Container>
    );
}
